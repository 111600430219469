<template class="mr-3">
  <div class="card">
    <div class="card-body">
      <div>
        <p>{{ $t("all.filterBy") }}:</p>
        <a-select
          id="selectMonth"
          :placeholder="$t('recette.tousMois')"
          style="width: 200px; margin-left: 15px"
          option-filter-prop="children"
          show-search
          :filter-option="filterOption"
          @change="handleChangeFilter"
          default-value="all"
        >
          <a-select-option value="all">
            {{ $t("recette.tousMois") }}
          </a-select-option>
          <a-select-option value="01"> {{ moment.months(0) }} </a-select-option>
          <a-select-option value="02"> {{ moment.months(1) }} </a-select-option>
          <a-select-option value="03"> {{ moment.months(2) }} </a-select-option>
          <a-select-option value="04"> {{ moment.months(3) }} </a-select-option>
          <a-select-option value="05"> {{ moment.months(4) }} </a-select-option>
          <a-select-option value="06"> {{ moment.months(5) }} </a-select-option>
          <a-select-option value="07"> {{ moment.months(6) }} </a-select-option>
          <a-select-option value="08"> {{ moment.months(7) }} </a-select-option>
          <a-select-option value="09"> {{ moment.months(8) }} </a-select-option>
          <a-select-option value="10">
            {{ moment.months(9) }}
          </a-select-option>
          <a-select-option value="11">
            {{ moment.months(10) }}
          </a-select-option>
          <a-select-option value="12">
            {{ moment.months(11) }}
          </a-select-option>
        </a-select>
        <a-button
          type="file-pdf"
          @click="generatePdf"
          style="margin-bottom: 20px; float: right"
        >
          <a-icon type="file-pdf" @click="generatePdf" />
          {{ $t("action.imprimerTab") }}
        </a-button>
        <json-csv
          class="ant-btn"
          style="margin-right: 20px; float: right"
          :data="csvToExport"
          :name="'Chiffre daffaire.csv'"
          ><a-icon
            class="anticon mt-2"
            style="color: green"
            type="file-excel"
          /><span>CSV</span></json-csv
        >
      </div>
      <div>
        <a-table
          @change="tableChanged"
          :loading="tableLoading"
          rowKey="_id"
          :pagination="true"
          :data-source="data"
          :columns="columns"
        >
          <template
            slot="expandedRowRender"
            slot-scope="record"
            style="margin: 0"
          >
            <table class="table table-striped" v-if="record.type == 'activity'">
              <thead>
                <th>{{ $t("recette.mois") }}</th>
                <th>{{ $t("recette.montant") }}</th>
                <th>{{ $t("topBar.status") }}</th>
              </thead>
              <tbody>
                <tr v-if="record.reduction.inscription != undefined && mF">
                  <td>{{ $t("recette.inscription") }}</td>
                  <td>
                    <del v-if="record.reduction.inscription > 0">
                      {{ record.activity.inscription }}
                    </del>
                    <div v-else>{{ record.activity.inscription }}</div>
                    <sup
                      v-if="record.reduction.inscription > 0"
                      class="text-success"
                      >-{{ record.reduction.inscription }}%</sup
                    >
                    <div v-if="record.reduction.inscription > 0">
                      ={{
                        record.activity.inscription -
                        (record.activity.inscription *
                          record.reduction.inscription) /
                          100
                      }}
                    </div>
                  </td>
                  <td>
                    {{
                      record.inscription
                        ? $t("paiement.paye")
                        : $t("paiement.impaye")
                    }}
                  </td>
                </tr>
                <tr v-for="(month, key) in record.months" :key="key">
                  <td>{{ getMonthName(key) }} ({{ key }})</td>
                  <td>
                    <div>
                      {{ record.activity.monthly }}
                      <sup
                        v-if="record.reduction.monthly > 0"
                        class="text-success"
                        >-{{ record.reduction.monthly }}%</sup
                      >
                      <div v-if="record.reduction.monthly > 0">
                        ={{
                          record.activity.monthly -
                          (record.activity.monthly * record.reduction.monthly) /
                            100
                        }}
                      </div>
                    </div>
                  </td>
                  <td>
                    {{
                      record.months[key]
                        ? $t("paiement.paye")
                        : $t("paiement.impaye")
                    }}
                  </td>
                </tr>
              </tbody>
            </table>
            <table class="table table-striped" v-else>
              <thead>
                <th>{{ $t("recette.mois") }}</th>
                <th>{{ $t("recette.montant") }}</th>
                <th>{{ $t("topBar.status") }}</th>
              </thead>
              <tbody>
                <tr v-if="record.reduction.inscription != undefined && mF">
                  <td>{{ $t("recette.inscription") }}</td>
                  <td>
                    <del v-if="record.reduction.inscription > 0">
                      {{ record.classRoom.inscription }}
                    </del>
                    <div v-else>{{ record.classRoom.inscription }}</div>
                    <sup
                      v-if="record.reduction.inscription > 0"
                      class="text-success"
                      >-{{ record.reduction.inscription }}%</sup
                    >
                    <div v-if="record.reduction.inscription > 0">
                      ={{
                        record.classRoom.inscription -
                        (record.classRoom.inscription *
                          record.reduction.inscription) /
                          100
                      }}
                    </div>
                  </td>
                  <td>
                    {{
                      record.inscription
                        ? $t("paiement.paye")
                        : $t("paiement.impaye")
                    }}
                  </td>
                </tr>
                <tr v-for="(month, key) in record.months" :key="key">
                  <td>{{ getMonthName(key) }} ({{ key }})</td>
                  <td>
                    <div>
                      {{ record.classRoom.monthly }}
                      <sup
                        v-if="record.reduction.monthly > 0"
                        class="text-success"
                        >-{{ record.reduction.monthly }}%</sup
                      >
                      <div v-if="record.reduction.monthly > 0">
                        ={{
                          record.classRoom.monthly -
                          (record.classRoom.monthly *
                            record.reduction.monthly) /
                            100
                        }}
                      </div>
                    </div>
                  </td>
                  <td>
                    {{
                      record.months[key]
                        ? $t("paiement.paye")
                        : $t("paiement.impaye")
                    }}
                  </td>
                </tr>
              </tbody>
            </table>
          </template>
          <template slot="status" slot-scope="text">
            {{ text == "payed" ? $t("all.oui") : $t("all.non") }}
          </template>
          <template slot="parent" slot-scope="text, record">
            {{ record.parent.father.firstName }}
            {{ record.parent.father.lastName }}
          </template>
          <template slot="phone" slot-scope="text, record">
            {{ record.parent.father.phone }}<br />
            {{ record.parent.mother.phone }}
          </template>
          <template slot="student" slot-scope="text, record">
            {{ record.student.firstName }} {{ record.student.lastName }}
          </template>
          <template slot="type" slot-scope="text, record">
            <div>
              <div v-if="text == 'activity'">
                {{ $t("menu.activites") }} / {{ record.service }}:
                {{ record.name }}
              </div>
              <div v-else>{{ $t("menu.scolarite") }} / {{ record.name }}</div>
            </div>
          </template>
          <div
            slot="filterDropdown"
            slot-scope="{
              setSelectedKeys,
              selectedKeys,
              confirm,
              clearFilters,
              column,
            }"
            style="padding: 8px"
          >
            <a-input
              v-ant-ref="(c) => (searchInput = c)"
              :placeholder="`${$t('personnel.chercher')} ${column.title}`"
              :value="selectedKeys[0]"
              style="width: 188px; margin-bottom: 8px; display: block"
              @change="
                (e) => setSelectedKeys(e.target.value ? [e.target.value] : [])
              "
              @pressEnter="
                () => handleSearch(selectedKeys, confirm, column.dataIndex)
              "
            />
            <a-button
              type="primary"
              icon="search"
              size="small"
              style="width: 90px; margin-right: 8px"
              @click="
                () => handleSearch(selectedKeys, confirm, column.dataIndex)
              "
              >{{ $t("paiement.chercher") }}</a-button
            >
            <a-button
              size="small"
              style="width: 90px"
              @click="() => handleReset(clearFilters)"
              >{{ $t("action.reinitialiser") }}</a-button
            >
          </div>
          <a-icon
            slot="filterIcon"
            slot-scope="filtered"
            type="search"
            :style="{ color: filtered ? '#108ee9' : undefined }"
          />
          <template
            slot="customRender"
            slot-scope="text, record, index, column"
          >
            <span v-if="searchText && searchedColumn === column.dataIndex">
              <template
                v-for="(fragment, i) in text
                  .toString()
                  .split(
                    new RegExp(`(?<=${searchText})|(?=${searchText})`, 'i')
                  )"
              >
                <mark
                  v-if="fragment.toLowerCase() === searchText.toLowerCase()"
                  :key="i"
                  class="highlight"
                  >{{ fragment }}</mark
                >
                <template v-else>{{ fragment }}</template>
              </template>
            </span>
            <template v-else>{{ text }}</template>
          </template>
          <template slot="delete" slot-scope="text, record">
            <a-popconfirm
              :title="$t('all.sureToDelete')"
              @confirm="() => onDelete(record.key)"
            >
              <a-button type="danger">
                <a-icon type="delete" href="javascript:;" />{{
                  $t("action.supprimer")
                }}
              </a-button>
            </a-popconfirm>
          </template>
        </a-table>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import apiClient from "@/services/axios";
import JsPDF from "jspdf";
import "jspdf-autotable";
import { mapState } from "vuex";
import writtenNumber from "written-number";
import JsonCsv from "vue-json-csv";
import moment from "moment";

const getStatus = (entry) => {
  if (!entry.inscription) return "impayed";
  else {
    if (entry.once == false && entry.months) {
      for (const [key, value] of Object.entries(entry.months)) {
        if (!value) return "impayed";
      }
    }
    return "payed";
  }
};

const activityFormater = (activityFees) => {
  let data = {
    _id: activityFees._id,
    name: activityFees.activity[0].designation,
    once: activityFees.activity[0].once,
    inscription: activityFees.inscription,
    months: activityFees.months,
    service: activityFees.service,
    reduction: activityFees.reduction,
    activity: activityFees.activity[0],
    student: activityFees.student[0],
    parent: activityFees.parent[0],
    type: "activity",
  };
  let money = parseInt(data.activity.inscription);
  money = money - (money * data.reduction.inscription) / 100;
  if (!data.once && data.months) {
    const monthNrb = Object.keys(data.months).length;
    const monthCost =
      data.activity.monthly -
      (data.activity.monthly * data.reduction.monthly) / 100;
    money = money + monthNrb * monthCost;
  }
  data.money = money;
  return data;
};

const getMonthName = (monthString) => {
  const months = {};
  const monthsArr = moment.months();
  for (let i = 0; i < monthsArr.length; i++) months[i + 1] = monthsArr[i];
  return monthString ? months[parseInt(monthString.split("-")[1])] : null;
};
function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
}
export default {
  components: {
    JsonCsv,
  },
  async created() {
    await apiClient
      .post("/students/activity/payment/filter", {
        query: {
          status: "active",
        },
        aggregation: [
          {
            $lookup: {
              from: "activities",
              localField: "activity",
              foreignField: "_id",
              as: "activity",
            },
          },
          {
            $lookup: {
              from: "services",
              localField: "activity.service",
              foreignField: "_id",
              as: "service",
            },
          },
          {
            $lookup: {
              from: "students",
              localField: "student",
              foreignField: "_id",
              as: "student",
            },
          },
          {
            $lookup: {
              from: "parents",
              localField: "student.parent",
              foreignField: "_id",
              as: "parent",
            },
          },
          {
            $lookup: {
              from: "services",
              localField: "activity.service",
              foreignField: "_id",
              as: "service",
            },
          },
          {
            $project: {
              inscription: 1,
              reduction: 1,
              months: 1,
              service: { name: 1 },
              student: {
                firstName: 1,
                lastName: 1,
              },
              parent: {
                father: 1,
                mother: 1,
              },
              activity: {
                _id: 1,
                once: 1,
                inscription: 1,
                monthly: 1,
                start: 1,
                end: 1,
                designation: 1,
                order: 1,
              },
              service: { $arrayElemAt: ["$service.name", 0] },
            },
          },
        ],
      })
      .then((res) => {
        let dataList = res.data.map((elem) => activityFormater(elem));
        dataList.forEach((elem) => {
          const status = getStatus(elem);
          this.data.push({
            ...elem,
            status: status,
          });
          this.rowData.push({
            ...elem,
            status: status,
          });
        });
      });
    await apiClient
      .post("/classrooms/payment/filter", {
        query: {},
        aggregation: [
          {
            $lookup: {
              from: "students",
              localField: "student",
              foreignField: "_id",
              as: "student",
            },
          },
          {
            $lookup: {
              from: "parents",
              localField: "student.parent",
              foreignField: "_id",
              as: "parent",
            },
          },
          {
            $lookup: {
              from: "classrooms",
              localField: "classRoom",
              foreignField: "_id",
              as: "classroom",
            },
          },
          {
            $project: {
              _id: 1,
              months: 1,
              student: {
                firstName: 1,
                lastName: 1,
              },
              parent: {
                father: 1,
                mother: 1,
              },
              inscription: 1,
              reduction: 1,
              classroom: {
                _id: 1,
                name: 1,
                inscription: 1,
                monthly: 1,
              },
            },
          },
        ],
      })
      .then((res) => {
        let dataList = res.data.map((elem) => {
          let data = {
            _id: elem._id,
            name: elem.classroom[0].name,
            classRoom: elem.classroom[0],
            inscription: elem.inscription,
            reduction: elem.reduction,
            months: elem.months,
            student: elem.student[0],
            parent: elem.parent[0],
            once: false,
            type: "scolarity",
          };
          let money = parseInt(data.classRoom.inscription);
          money = money - (money * data.reduction.inscription) / 100;
          if (!data.once) {
            const monthNrb = Object.keys(data.months).length;
            const monthCost =
              data.classRoom.monthly -
              (data.classRoom.monthly * data.reduction.monthly) / 100;
            money = money + monthNrb * monthCost;
          }
          data.money = money;
          return data;
        });
        dataList.forEach((elem) => {
          const status = getStatus(elem);
          this.data.push({
            ...elem,
            status: status,
          });
          this.rowData.push({
            ...elem,
            status: status,
          });
        });
      });
    apiClient
      .post("/receipt_types/filter", {
        query: { status: "active" }, //schoolarYear: this.settings.activeSchoolarYear
      })
      .then((res) => (this.receiptTypes = res.data))
      .catch((e) =>
        this.$message.warning(this.$t("warning.connectionProblem"))
      );
    this.cacheData = this.data.map((item) => ({ ...item }));
    this.filtredTable = this.data;
    this.tableLoading = false;
  },
  computed: {
    ...mapState(["settings"]),
    csvToExport() {
      const data = [...this.filtredTable];

      return data.map((item) => {
        let paye = 0;
        let nonPaye = 0;

        if (item.inscription)
          paye += item.classRoom
            ? item.classRoom.inscription
            : item.activity.inscription;
        else
          item.classRoom
            ? item.classRoom.inscription
            : item.activity.inscription;
        _.forEach(item.months, (value, key) => {
          /* totale += item.activity
            ? item.activity.monthly
            : item.classRoom.monthly; */
          if (value) {
            if (item.type == "activity") {
              paye += item.activity ? item.activity.monthly : 0;
            } else paye += item.classRoom ? item.classRoom.monthly : 0;
          } else {
            if (item.type == "activity")
              nonPaye += item.activity ? item.activity.monthly : 0;
            else nonPaye += item.classRoom ? item.classRoom.monthly : 0;
          }
        });
        return {
          nomClient: `${item.parent.father.firstName} ${item.parent.father.lastName} ${item.parent.mother.firstName} ${item.parent.mother.lastName}`,
          Payé: item.status == "payed" ? "oui" : "non",
          enfant: `${item.student.firstName} ${item.student.lastName}`,
          Frais:
            item.type == "activity"
              ? `${item.activity.designation} : ${item.name}`
              : ` Scolarité : ${item.classRoom.name}`,
          montantTotale: paye + nonPaye,
          montantPayé: paye,
          montantNonPaye: nonPaye,
        };
      });
    },
  },
  data() {
    return {
      mF: true,
      moment,
      tableLoading: true,
      rowData: [],
      editingKey: "",
      receiptTypes: [],
      students: [],
      visibleParentFile: false,
      data: [],
      searchText: "",
      searchInput: null,
      searchedColumn: "",
      targetKeys: [],
      previewVisible: false,
      previewImage: "",
      value: 1,
      loadingData: false,
      visibleData: false,
      indeterminate: true,
      checkAll: false,
      columns: [
        {
          title: this.$t("recette.nom"),
          dataIndex: "parent",
          key: "parent",
          width: "150px",
          scopedSlots: {
            filterDropdown: "filterDropdown",
            filterIcon: "filterIcon",
            customRender: "parent",
          },
          onFilter: (value, record) => {
            let clientName =
              record.parent.father.firstName +
              " " +
              record.parent.father.lastName;

            return clientName.toLowerCase().includes(value.toLowerCase());
          },
          onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
              setTimeout(() => {
                this.searchInput.focus();
              });
            }
          },
        },

        {
          title: this.$t("recette.payeQues"),
          dataIndex: "status",
          key: "status",
          width: "150px",
          scopedSlots: {
            filterDropdown: "filterDropdown",
            filterIcon: "filterIcon",
            customRender: "status",
          },
          onFilter: (value, record) =>
            (record.status == "payed" ? "oui" : "non") == value.toLowerCase(),
          onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
              setTimeout(() => {
                this.searchInput.focus();
              });
            }
          },
        },
        {
          title: this.$t("recette.enfant"),
          dataIndex: "student",
          key: "student",
          width: "150px",
          scopedSlots: {
            filterDropdown: "filterDropdown",
            filterIcon: "filterIcon",
            customRender: "student",
          },
          onFilter: (value, record) => {
            let studentName =
              record.student.firstName + " " + record.student.lastName;
            return studentName.toLowerCase().includes(value.toLowerCase());
          },
          onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
              setTimeout(() => {
                this.searchInput.focus();
              });
            }
          },
        },
        {
          title: this.$t("recette.frais"),
          dataIndex: "type",
          key: "type",
          width: "300px",
          scopedSlots: {
            filterDropdown: "filterDropdown",
            filterIcon: "filterIcon",
            customRender: "type",
          },
          onFilter: (value, record) => {
            const testString =
              record.type == "activity"
                ? `Activité / ${record.service}: ${record.name}`
                : `Scolarité / ${record.name}`;
            return testString.toLowerCase().includes(value.toLowerCase());
          },
          onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
              setTimeout(() => {
                this.searchInput.focus();
              });
            }
          },
        },
        {
          title: this.$t("recette.montantTotal"),
          dataIndex: "money",
          key: "money",
          width: "300px",
          scopedSlots: {
            filterDropdown: "filterDropdown",
            filterIcon: "filterIcon",
            customRender: "money",
          },
          onFilter: (value, record) =>
            (record.money + " ").toLowerCase().includes(value.toLowerCase()),
          onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
              setTimeout(() => {
                this.searchInput.focus();
              });
            }
          },
        },
      ],
      filtredTable: [],
    };
  },
  methods: {
    getMonthName,
    tableChanged(pagination, filters, sorter, extra) {
      this.filtredTable = extra.currentDataSource;
    },
    getStudent(id) {
      try {
        return this.students.filter((s) => s._id == id)[0];
      } catch {
        return null;
      }
    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      );
    },
    handleSearch(selectedKeys, confirm, dataIndex) {
      confirm();
      this.searchText = selectedKeys[0];
      this.searchedColumn = dataIndex;
    },
    handleReset(clearFilters) {
      clearFilters();
      this.searchText = "";
    },
    generatePdf() {
      this.$gtag.event("Imp Chiffre d'affaires", {
        event_category: "Impression PDF",
        event_label: "declarations:Chiffre d'affaires",
        value: 1,
      });

      let selectMonth = document.getElementById("selectMonth").innerText;
      let arrayOfImages = this.settings.image;
      for (var i = 0; i < arrayOfImages.length; i++) {
        if (this.settings.activeBuilding === arrayOfImages[i].db) {
          var imgData = new Image();
          imgData.src = arrayOfImages[i].logo;
        }
      }
      const doc = new JsPDF();
      doc.setFont("Amiri");
      var date = new Date();
      let monthNumber = date.getMonth();

      var titre = "Chiffre d'affaire";
      const options = {
        weekday: "long",
        year: "numeric",
        month: "long",
        day: "numeric",
      };
      doc.setLanguage("ar");
      doc.setFontSize(15);
      doc.text(80, 60, titre);
      doc.text(92, 70, selectMonth);
      doc.setFontSize(10);
      try {
        doc.addImage(imgData, "JPEG", 8, 8, 40, 40);
      } catch (err) {
        console.log(err);
      }
      doc.setFontSize(12);
      doc.text(150, 40, date.toLocaleDateString("fr-FR", options));
      var arr = [];
      const array = [];
      var somme = 0;

      for (let i = 0; i < this.filtredTable.length; i++) {
        let currentIndex = 0;
        let monthsUnpaied = 0;
        let inscri = false;
        if (this.filtredTable[i].type == "scolarity") {
          this.filtredTable[i].classRoom.inscription === 0
            ? (monthsUnpaied = 0)
            : ((monthsUnpaied = 1), (inscri = true));
        } else {
          this.filtredTable[i].activity.inscription === 0
            ? (monthsUnpaied = 0)
            : ((monthsUnpaied = 1), (inscri = true));
        }
        if (this.filtredTable[i].months) {
          for (var key of Object.keys(this.filtredTable[i].months)) {
            monthsUnpaied++;
          }
        }
        if (currentIndex === 0) {
          currentIndex++;
          arr.unshift({
            rowSpan: monthsUnpaied,
            content:
              this.filtredTable[i].student.firstName +
              " " +
              this.filtredTable[i].student.lastName,
            styles: { valign: "middle" },
          });
          arr.unshift({
            rowSpan: monthsUnpaied,
            content: this.filtredTable[i].parent.father.phone,
            styles: { valign: "middle" },
          });
          arr.unshift({
            rowSpan: monthsUnpaied,
            content:
              this.filtredTable[i].parent.father.firstName +
              " " +
              this.filtredTable[i].parent.father.lastName,
            styles: { valign: "middle" },
          });
        }
        if (!currentIndex) {
          arr.push(
            this.filtredTable[i].parent.father.firstName +
              " " +
              this.filtredTable[i].parent.father.lastName
          );
          arr.push(this.filtredTable[i].parent.father.phone);
          arr.push(
            this.filtredTable[i].student.firstName +
              " " +
              this.filtredTable[i].student.lastName
          );
        }
        if (inscri) {
          arr.push("Inscription " + this.filtredTable[i].name);
          this.filtredTable[i].inscription == true
            ? arr.push({
                content: "payé",
                styles: { textColor: [14, 115, 31], fontStyle: "bold" },
              })
            : arr.push({
                content: "impayé",
                styles: { textColor: [209, 23, 45], fontStyle: "bold" },
              });
          if (this.filtredTable[i].type == "scolarity") {
            arr.push(
              this.filtredTable[i].classRoom.inscription *
                (1 - this.filtredTable[i].reduction.inscription / 100)
            );
            somme += parseFloat(
              this.filtredTable[i].classRoom.inscription *
                (1 - this.filtredTable[i].reduction.inscription / 100)
            );
          } else {
            arr.push(
              this.filtredTable[i].activity.inscription *
                (1 - this.filtredTable[i].reduction.inscription / 100)
            );
            somme += parseFloat(
              this.filtredTable[i].activity.inscription *
                (1 - this.filtredTable[i].reduction.inscription / 100)
            );
          }
          array.push(arr);
          arr = [];
        }
        if (this.filtredTable[i].months) {
          for (var key of Object.keys(this.filtredTable[i].months)) {
            let paymentDate = new Date(key);
            let monthName = paymentDate.toLocaleString("fr-FR", {
              month: "long",
            });
            arr.push(monthName + " - " + this.filtredTable[i].name);
            this.filtredTable[i].months[key] == true
              ? arr.push({
                  content: "payé",
                  styles: { textColor: [14, 115, 31], fontStyle: "bold" },
                })
              : arr.push({
                  content: "impayé",
                  styles: { textColor: [209, 23, 45], fontStyle: "bold" },
                });
            if (this.filtredTable[i].type == "scolarity") {
              arr.push(
                this.filtredTable[i].classRoom.monthly *
                  (1 - this.filtredTable[i].reduction.monthly / 100)
              );
              somme += parseFloat(
                this.filtredTable[i].classRoom.monthly *
                  (1 - this.filtredTable[i].reduction.monthly / 100)
              );
            } else {
              arr.push(
                this.filtredTable[i].activity.monthly *
                  (1 - this.filtredTable[i].reduction.monthly / 100)
              );
              somme += parseFloat(
                this.filtredTable[i].activity.monthly *
                  (1 - this.filtredTable[i].reduction.monthly / 100)
              );
            }
            //colspan
            array.push(arr);
            arr = [];
          }
        }
      }
      doc.autoTable({
        theme: "grid",
        styles: { font: "Amiri" },
        startY: doc.pageCount > 1 ? doc.autoTableEndPosY() + 20 : 80,
        head: [
          ["Nom client", "Téléphone", "Stagiaire", "Frais", "Etat", "Montant"],
        ],
        body: array,
        foot: [
          [
            "Total",
            {
              colSpan: 4,
              content: writtenNumber(somme, { lang: "fr" }) + " DT",
              styles: { halign: "center" },
            },
            parseFloat(somme).toFixed(2),
          ],
        ],
      });
      //page numbering
      const pages = doc.internal.getNumberOfPages();
      const pageWidth = doc.internal.pageSize.width;
      const pageHeight = doc.internal.pageSize.height;
      doc.setFontSize(8); //Optional

      for (let j = 1; j < pages + 1; j++) {
        let horizontalPos = pageWidth / 2;
        let verticalPos = pageHeight - 10;
        doc.setPage(j);
        doc.text(`Page ${j} / ${pages}`, horizontalPos, verticalPos, {
          align: "center",
        });
      }
      //end page numbering
      doc.save("Chiffre d'affaire.pdf");
    },
    afterFilter(result) {
      return result.map((data) => {
        if (data.type == "activity") {
          let money = 0;
          if (data.inscription != undefined) {
            money = parseInt(data.activity.inscription);
            money = money - (money * data.reduction.inscription) / 100;
          }
          if (!data.once) {
            const monthNrb = Object.keys(data.months).length;
            const monthCost =
              data.activity.monthly -
              (data.activity.monthly * data.reduction.monthly) / 100;
            money = money + monthNrb * monthCost;
          }
          data = {
            ...data,
            money: money,
          };
        } else {
          let money = 0;
          if (data.inscription != undefined) {
            money = parseInt(data.classRoom.inscription);
            money = money - (money * data.reduction.inscription) / 100;
          }
          if (!data.once) {
            const monthNrb = Object.keys(data.months).length;
            const monthCost =
              data.classRoom.monthly -
              (data.classRoom.monthly * data.reduction.monthly) / 100;
            money = money + monthNrb * monthCost;
          }
          data = {
            ...data,
            money: money,
          };
        }
        return data;
      });
    },
    handleChangeFilter(filter) {
      const clone = this.rowData.slice();
      if (filter == "all") {
        this.data = [...clone];
        this.mF = true;
      } else {
        this.mF = false;
        let result = [];
        if (filter == "inscription")
          clone.forEach((elem) =>
            result.push({ ...elem, once: true, months: {} })
          );
        else {
          result = [...clone.filter((elem) => !elem.once)];
          result = result
            .filter((_) => _)
            .map((elem) => {
              elem = { ...elem, inscription: undefined };
              try {
                const monthKeys = Object.keys(elem.months).filter(
                  (e) => e.split("-")[1] == filter
                );
                if (monthKeys.length > 0) {
                  let months = {};
                  monthKeys.forEach((key) => {
                    months[key] = elem.months[key];
                  });
                  elem = {
                    ...elem,
                    months: months,
                  };
                } else elem = { ...elem, months: {} };
                return elem;
              } catch {
                return { ...elem, months: {}, inscription: undefined };
              }
            });
          result = result.filter(
            (elem) =>
              elem.inscription != undefined ||
              Object.keys(elem.months).length > 0
          );
        }
        this.data = [...this.afterFilter(result)];
        this.data = [...this.data.filter((elem) => elem.money > 0)];
      }
      this.filtredTable = this.data;
      this.$forceUpdate();
    },
  },
};
</script>
